<template>
  <q-card class="column justify-between">
    <q-card-section
      class="bg-primary text-white shadow-1 q-py-sm absolute"
      style="width: 100%"
    >
      <div class="text-h6 text-weight-bold">Overzicht</div>
    </q-card-section>
    <q-card-section class="q-pa-none q-pt-xl">
      <q-list>
        <q-item
          v-for="(definition, index) in definitions"
          :key="index"
          class="q-my-sm"
        >
          <q-item-section>
            <q-item-label>{{ definition.title }}</q-item-label>
            <q-item-label
              v-for="(item, index) in definition.items"
              :key="index"
              caption
            >
              <div
                v-if="
                  !item.some(
                    (element) => element.value === null || element.value === ''
                  )
                "
              >
                <span v-for="(value, index) in item" :key="index" caption>
                  {{ value?.value ?? value }}
                </span>
              </div>
            </q-item-label>
          </q-item-section>

          <q-item-section side top v-show="currentStep > definition.iteration">
            <q-icon name="check" color="green" />
          </q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
    <q-card-section class="q-pa-none">
      <q-list>
        <q-separator></q-separator>
        <q-item>
          <q-item-section>
            <q-item-label caption> CTPID: {{ ctpid }} </q-item-label>
            <q-item-label caption> Project: {{ project }} </q-item-label>
            <q-item-label caption> Record ID: {{ recordId }} </q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-btn
              class="text-weight-bolder"
              icon="refresh"
              color="primary"
              label="Refresh"
              no-caps
              @click="reload()"
            ></q-btn>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
  </q-card>
</template>
<script setup>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { get_definitions } from "../../plugins/overview.js";

//* VueUse
const $store = useStore();

//* Computed
const currentStep = computed(() => $store.getters.record.current_step);
const ctpid = computed(() => $store.getters.steamVelden.RecordID);
const project = computed(() => $store.getters.steamVelden.project);
const recordId = computed(() => $store.getters.record.id);
const definitions = computed(() => get_definitions());

function reload() {
  window.location.reload();
}
</script>
<style lang=""></style>
