import store from "../store/index";
import { computed } from "@vue/reactivity";

const stepper_settings = store.getters.component_settings?.stepper ?? {};

//* Address
const street = computed(() => store.getters.record.Straat);
const housenumber = computed(() => store.getters.record.Huisnummer);
const city = computed(() => store.getters.record.Woonplaats);

//* CurrentConsumption
const consumptionNormal = computed(
  () => store.getters.projectSpecific["verbruik electra"] ?? null
);
const consumptionLow = computed(
  () => store.getters.projectSpecific["verbruik electra 2"] ?? null
);
const consumptionGas = computed(
  () => store.getters.projectSpecific["verbruik gas"] ?? null
);
const redelivery = computed(
  () => store.getters.projectSpecific["verbruik electra teruglevering"] ?? null
);

//* Offers
//* Energyplus
const homeAdvantage = computed(
  () => store.getters.projectSpecific["Info_Thuisvoordeel"] ?? null
);
const applyHomeAdvantage = computed(
  () => store.getters.projectSpecific["Aanmelden_Thuisvoordeel"] ?? null
);
const infoHomeApp = computed(
  () => store.getters.projectSpecific["Info_Thuisapp"] ?? null
);
const comfortSolution = computed(
  () => store.getters.projectSpecific["comfortoplossingen"] ?? null
);
const newsletter = computed(
  () => store.getters.projectSpecific["nieuwsbrief"] ?? null
);
const offersPartners = computed(
  () => store.getters.projectSpecific["aanbiedingenpartners"] ?? null
);

//* ProspectInformation
const initials = computed(() => store.getters.record.Initialen);
const prefix = computed(() => store.getters.record.Tussenvoegsel);
const lastName = computed(() => store.getters.record.Achternaam);
const phone = computed(() => store.getters.record.Telefoon);
const email = computed(() => store.getters.record.Email);

//* Voicelog

const definitions = {
  Address: {
    title: "Aansluiting",
    items: [[street, housenumber], [city]],
  },
  CurrentConsumption: {
    title: "Huidige Situatie",
    items: [
      ["Normaal: ", consumptionNormal],
      ["Laag: ", consumptionLow],
      ["Gas: ", consumptionGas],
      ["Teruglevering: ", redelivery],
    ],
  },
  Offers: {
    title: "Aanbod",
    items: [],
  },
  EnergyPlus: {
    title: "Energie+",
    items: [
      ["Info Thuisvoordeel: ", homeAdvantage],
      ["Aanmelden Thuisvoordeel: ", applyHomeAdvantage],
      ["Info Thuisapp: ", infoHomeApp],
      ["Comfortoplossing: ", comfortSolution],
      ["Nieuwsbrief: ", newsletter],
      ["Aanbiedingen Partners: ", offersPartners],
    ],
  },
  ProspectInformation: {
    title: "Prospectgegevens",
    items: [[initials], [prefix], [lastName], [phone], [email]],
  },
  Voicelog: {
    title: "Samenvatting",
    items: [],
  },
};

const get_definitions = () => {
  const current_definitions = [];
  let iteration = 1;
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(stepper_settings)) {
    if (definitions[key]) {
      definitions[key].iteration = iteration;
      current_definitions.push(definitions[key]);

      iteration++;
    }
  }

  return current_definitions;
};

export { get_definitions };
